import Vue from 'vue';
import VueRouter from 'vue-router';
import indexView from '../views/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: indexView,
  },
  {
    path: '/replacing',
    name: 'replacing',

    component: () => import('../views/replacing/index.vue'),
  },
  {
    path: '/openRechargeBox',
    name: 'openRechargeBox',
    component: () => import('../views/hongbao/openRechargeBox.vue'),
  },
  {
    path: '/pk',
    name: 'pk',
    component: () => import('../views/pkHome/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Home/common/login.vue'),
  },
  {
    path: '/roll',
    name: 'roll',
    component: () => import('../views/rollHome/index.vue'),
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: () => import('../views/shopping/index.vue'),
  },
  {
    path: '/knapsack',
    name: 'knapsack',
    component: () => import('../views/Home/common/knapsack.vue'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/Home/common/recharge.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/Home/common/notifications.vue'),
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/Home/common/personal.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Home/common/help.vue'),
  },
  {
    path: '/open',
    name: 'open',
    component: () => import('../views/openbox/index.vue'),
    meta: {
      iskeep: true,
    },
  },
  {
    path: '/openbox',
    name: 'openbox',
    component: () => import('../views/openbox/common/openbox.vue'),
  },
  {
    path: '/dream',
    name: 'dream',
    component: () => import('../views/dream/index.vue'),
  },
  {
    path: '/hongbao',
    name: 'hongbao',
    component: () => import('../views/hongbao/hongbao.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/index.vue'),
  },
  {
    path: '/creatroom',
    name: 'creatroom',
    component: () => import('../views/pkHome/creatroom.vue'),
  },
  {
    path: '/roomDetail',
    name: 'roomDetail',
    component: () => import('../views/pkHome/roomDetail.vue'),
  },
  {
    path: '/rollDetail',
    name: 'rollDetail',
    component: () => import('../views/rollHome/common/rollDetail.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
