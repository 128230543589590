<template>
  <div class="box_item" @click.stop="handleClick(boxItem.boxId)">
    <div v-if="boxItem.boxId && boxItem.rechargeGrade == null" class="box_item_id">ID：{{ boxItem.boxId }}</div>
    <!-- <div v-if="boxItem.rechargeGrade != null" class="box_item_id">等级{{ boxItem.rechargeGrade }}</div> -->
    <div class="box_item_img center">
      <img :src="boxItem.boxImg01" alt="" />
      <img v-if="boxItem.boxImg02" :src="boxItem.boxImg02" alt="" class="bg" />
    </div>
    <div class="box_item_name">{{ boxItem.boxName }}</div>
    <price v-if="boxItem.rechargeGrade == null" :price="boxItem.price" :size="$store.state.mode == 'mobile' ? 'middle' : 'large'" />
    <div class="box_item_btn">
      <div class="btn off" v-if="isMe && boxItem.status == 0" @click.stop="handleOff(boxItem.boxId)">删除</div>
      <div class="btn examine" v-else-if="boxItem.status == 2">审核中</div>
      <div class="btn examine" v-else-if="boxItem.rechargeGrade != null && currentGrade < boxItem.rechargeGrade">等级不足</div>
      <!-- <div class="btn open" v-else-if="boxItem.rechargeGrade!=null" @click="open">打开</div> -->
      <div class="btn open" v-else>打开</div>
    </div>
    <div class="box_item_owner" v-if="boxItem.ownerId != 'admin'">玩家ID：{{ boxItem.ownerId }}</div>
    <div v-else style="height: 5px"></div>
  </div>
</template>

<script>
import { boxForbid } from '@/api/index';

export default {
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    boxItem: {
      type: Object,
      default: () => {},
    },
    currentGrade: {
      default: 0,
    },
    openModel: {
      type: String,
      default: 'openBox',
    },
  },
  data() {
    return {};
  },
  methods: {
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleOff(id) {
      this.playAudio();
      this.$confirm('确定要删除该盲盒吗?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        cancelButtonClass: 'active_btn',
        center: true,
      })
        .then(() => {
          boxForbid(id).then(res => {
            if (res?.data?.code == 200) {
              this.$emit('update', id);
              this.$message({ message: '已删除', type: 'success' });
            } else {
              this.$message({ message: res?.data?.msg || '删除失败', type: 'warning' });
            }
          });
        })
        .catch(() => {
          this.playAudio();
        });
    },
    handleClick(id) {
      this.$store.commit('playAudio');
      if (this.openModel == 'openBox') {
        this.$router.push({ path: '/openbox', query: { id } });
      } else if (this.openModel == 'rechargeLevelBox') {
        let flag = true;
        let model='level'
        if (this.currentGrade < this.boxItem.rechargeGrade) {
          flag = false;
        }
        this.$router.push({ path: '/openRechargeBox', query: { id, flag ,model} });
        // this.$emit('openLevelBox', id);
      } else if (this.openModel == 'rechargeFreeBox') {
        let flag = true;
        let model='free'
        this.$router.push({ path: '/openRechargeBox', query: { id,flag,model} });
        // this.$emit('openFreeBox', id);
      }
    },
  },
  mounted() {
    // console.log(this.boxItem.rechargeGrade);
  },
};
</script>

<style lang="less" scoped>
.box_item {
  display: flex;
  overflow: hidden;
  background: @bg2;
  position: relative;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  border: 2px solid @bd2;
  transition: all 0.3s;
  &:hover {
    border-color: @main;
    .bg {
      transition: all 0.2s ease;
      transform: scale(1.2);
    }
  }
  @media @max750 {
    border: none;
    background: none;
    padding-top: 10px;
    background: #101115;
  }
  .box_item_id {
    top: 8px;
    left: 12px;
    .sc(16px, #fff);
    position: absolute;
    @media @max750 {
      font-size: 14px;
    }
  }
  .box_item_img {
    .flex-center;
    .wh(180px, 180px);
    .bg-shadow(70%);
    margin-top: 30px;
    img {
      z-index: 2;
      .wh(100%, auto);
    }
    @media @max750 {
      width: 80%;
      padding: 0;
      height: calc(54vw * 0.56);
      box-sizing: border-box;
      img {
        .wh(auto, 100%);
      }
    }
  }
  .bg {
    width: 50%;
    position: absolute;
    transition: all 0.3s ease-in-out;
  }
  .box_item_name {
    width: 90%;
    .sc(20px, #ddd);
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    margin: 0 0 4px;
    text-overflow: ellipsis;
    @media @max750 {
      margin: 0;
      font-size: 16px;
    }
  }
  .box_item_btn {
    width: 50%;
    height: 40px;
    cursor: pointer;
    margin-top: 6px;
    @media @max750 {
      height: 32px;
    }
    .btn {
      .sc(20px, #fff);
      border-radius: 30px;
      .btn-style(100%, 100%);
      background: @bg3;
      .flex-center;
      @media @max750 {
        font-size: 16px;
      }
    }
    .open {
      height: 34px;
      margin-top: 4px;
      line-height: 34px;
      transition: all 0.3s;
      background: rgba(@main, 0.2);
      box-shadow: 0 0 3px 3px @main;
      &:hover {
        box-shadow: 0 0 5px 5px @main;
      }
      @media @max750 {
        height: 28px;
        line-height: 28px;
      }
    }
  }
  .box_item_owner {
    padding: 10px 0;
    text-align: center;
    .sc(16px, #fff);
    @media @max750 {
      font-size: 14px;
    }
  }
}
</style>
