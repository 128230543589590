<template>
  <div class="blind_box">
    <lineTitle :title="title" />
    <div class="blind_box_list">
      <boxCard v-for="(item, index) in boxList" :boxItem="item" :key="index" @update="update" />
    </div>
  </div>
</template>

<script>
import boxCard from './boxCard.vue';
import lineTitle from './lineTitle.vue';

export default {
  props: {
    title: {
      type: String,
      default: '盲盒系列',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    list: {
      handler(val) {
        this.boxList = [...val];
      },
      deep: true,
      immediate: true,
    },
  },
  components: { lineTitle, boxCard },
  data() {
    return {
      boxList: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    update() {
      this.$emit('update');
    },
  },
};
</script>

<style lang="less" scoped>
.blind_box {
  overflow: hidden;
  margin: 40px @pcSpace;
  .blind_box_list {
    .grid(5, 14px);
    margin-top: 40px;
  }
  @media @max750 {
    margin: 40px 12px;
    .blind_box_list {
      .grid(2, 10px);
      margin-top: 30px;
    }
  }
}
</style>
