import reqAxios from './api';

//登录
export const getLogin = data => {
  return reqAxios.post('/api/login', data);
};
//退出登录
export const logOut = () => {
  return reqAxios.post('/api/logout');
};
//注册
export const getRegister = data => {
  return reqAxios({
    method: 'post',
    url: '/api/register',
    data,
  });
};

// 实名认证
export const realNameAuthentication = data => {
  return reqAxios({
    url: `/api/user/realNameAuthentication`,
    method: 'post',
    data: data,
  });
};

//获取下级人数
export const getUserSubusser = () => {
  return reqAxios({
    url: '/api/user/rechargeCount',
    method: 'get',
  });
};
export const getUserSubRechange = () => {
  return reqAxios({
    url: '/api/user/getOrdersAmounts',
    method: 'get',
  });
};

// 判断是否充值
export const IsRecharge = () => {
  return reqAxios.get('/api/user/getUserIsRecharge');
};

// 刷新用户金币
export const updateUserData = () => {
  return reqAxios.post('/api/user/updateUserData');
};

// 获取用户信息
export const getUserInfo = () => {
  return reqAxios.get('/api/getInfo');
};

//roll房列表
export const getRollList = data => {
  return reqAxios({
    url: `/api/roll/getRollList`,
    method: 'post',
    data,
  });
};
//获取roll房信息
export const getRollDetail = rollId => {
  return reqAxios({
    url: `/api/roll/getRollDetails/${rollId}`,
    method: 'get',
  });
};

//加入roll房
export const joinRoll = data => {
  return reqAxios({
    url: `/api/roll/joinRoll`,
    method: 'post',
    data,
  });
};

// 获取用户参与Roll房
// export const getMyPartRollList = (data) => {
//   return reqAxios({
//     url: `/api/roll/getRollList`,
//     method: "get",
//     params: data,
//   });
// };

// 个人明细
export const getUserLogList = data => {
  return reqAxios({
    url: `/api/userAmountRecords/userAccountDetail`,
    method: 'post',
    data,
  });
};
//可充值钱数
export const getRechargelist = () => {
  return reqAxios({
    url: `/api/recharge/list`,
    method: 'get',
  });
};
//充值记录
export const orderList = () => {
  return reqAxios({
    url: `/admin/order/list`,
    method: 'get',
  });
};
//
export const jiuJiaPay = data => {
  return reqAxios({
    url: `/api/jiuJiaPay/createPay`,
    method: 'post',
    data: data,
  });
};
//
export const kamiPay = data => {
  return reqAxios({
    url: `/api/recharge/cardPay`,
    method: 'post',
    params: data,
  });
};

// 签到
export const attendance = () => {
  return reqAxios({
    url: `/api/attendanceRecord/attendance`,
    method: 'get',
  });
};
// 七日签到
export const attendance7day = () => {
  return reqAxios({
    url: `/api/attendanceRecord/sevenAttendance`,
    method: 'get',
  });
};

//当日排行榜
export const creditsRank = data => {
  return reqAxios({
    url: `api/userCreditsRecords/creditsRank/2/1/5`,
    method: 'get',
    params: data,
  });
};
// 获取下级流水列表
export const getUserxList = () => {
  return reqAxios({
    url: `/api/user/getLsjlList`,
    method: 'get',
  });
};

// 推广
export const userCreditsRecords = (page, size) => {
  return reqAxios({
    url: `api/userAmountRecords/pWelfareRecords/${page}/${size}`,
    method: 'get',
  });
};

//站内信-获取站内信列表
export const getMessageList = (data) => {
  return reqAxios({
    method: 'get',
    url: `/api/message/getMessageList/${data.pageNum}/${data.pageSize}`,
    // url: `/api/message/getMessageList?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
  });
};
export const readMessage = data => {
  return reqAxios({
    method: 'get',
    url: `/api/message/read/${data}`,
  });
};

export const removeMessage = data => {
  return reqAxios({
    method: 'delete',
    url: `/api/message/remove/${data}`,
  });
};

//首页顶部轮播
export const getWeapon = options => {
  return reqAxios({
    url: `/api/boxRecords/historyByCondition`,
    method: 'POST',
    data: options,
  });
};

//首页中间轮播
export const getHomeBanner = options => {
  return reqAxios.get('/api/websiteSetup/getBannerList', options);
};

//开箱接口
export const openBox = options => {
  return reqAxios({
    method: 'post',
    url: `/api/bindbox/openBox`,
    params: options,
  });
};

//开箱，武器分解 // 背包分解
export const decompose = options => {
  return reqAxios({
    method: 'post',
    url: `/api/userPackSack/decompose`,
    data: options,
  });
};
// 分解记录
export const userdecompseList = data => {
  return reqAxios({
    method: 'post',
    url: `/api/userPackSack/decomposeLog`,
    data,
  });
};

// 提取饰品
export const delivery = options => {
  return reqAxios({
    method: 'post',
    url: `/api/userPackSack/delivery`,
    data: options,
  });
};

//全部对战，查询所有对战列表
export const getFightList = data => {
  return reqAxios({
    url: '/api/fight/getFightList',
    method: 'post',
    data,
  });
};

//创建对战
export const createFight = data => {
  return reqAxios({
    method: 'post',
    url: '/api/fight/createFight',
    data,
  });
};
//玩家准备
/* /api/fight/seatrReady */
export const ApiSeatReady = fightId => {
  return reqAxios({
    url: `/api/fight/seatrReady?fightId=${fightId}`,
    method: 'get',
  });
};
//房主开始游戏
export const Playthegame = fightId => {
  return reqAxios({
    url: `/api/fight/fightBegin?fightId=${fightId}`,
    method: 'get',
  });
};
//游戏结束
/* /api/fight/fightEnd */
export const Gameover = fightId => {
  return reqAxios({
    method: 'get',
    url: `/api/fight/fightEnd?fightId=${fightId}`,
  });
};

//保存对战回合
// export const saveFightBoutData = (data) => {
//   return reqAxios({
//     method: "post",
//     url: "/api/fight/saveFightBoutData",
//     data: data,
//   });
// };
//获取对战回合
// export const getFightBoutNum = (fightId) => {
//   return reqAxios({
//     method: "post",
//     url: `/api/fight/getFightBoutNum/${fightId}`,
//   });
// };
//获取对战数据
export const getFightData = fightId => {
  return reqAxios({
    url: `/api/fight/getFightData/${fightId}`,
    method: 'get',
  });
};
//获取对战结果
export const getFightResult = fightId => {
  return reqAxios({
    url: `/api/fight/getFightResult/${fightId}`,
    method: 'get',
  });
};
//查询对战宝箱列表
export const getFightBoxList = data => {
  return reqAxios({
    url: `/api/fight/getFightBoxList`,
    method: 'get',
    params: data,
  });
};
//加入对战的验证
export const joinFightverify = fightId => {
  return reqAxios({
    url: `/api/fight/joinFightRoom`,
    method: 'post',
    params: fightId,
  });
};

//加入对战
export const joinFight = data => {
  return reqAxios({
    url: `/api/fight/joinFight`,
    method: 'post',
    params: data,
  });
};
//对战宝箱饰品
export const boxOrnament = id => {
  return reqAxios.get(`/api/fightAgainstBData/getFightAgainstBoxOrnamentsById?bid=${id}`);
};

//我的对战
export const getMyPartFightList = data => {
  return reqAxios({
    url: `/api/fight/getMyPartFightList`,
    method: 'get',
    params: data,
  });
};

//获取每一回合的对战结果
export const getpkrealdetial = data => {
  return reqAxios({
    url: '/api/fight/getFightRecord',
    method: 'get',
    params: data,
  });
};

//追梦 获取饰品列表

export const deramlist = data => {
  return reqAxios({
    method: 'get',
    url: `/api/luckyUpgrade/getOrnamentsList`,
    params: data,
  });
};

//追梦
export const deramrecord = data => {
  return reqAxios({
    method: 'post',
    url: `/api/luckyUpgrade/getUpgradeRecord`,
    data,
  });
};

//追梦
export const deramupgrade = data => {
  return reqAxios({
    method: 'post',
    url: `/api/luckyUpgrade/upgrade`,
    data: data,
  });
};

//用户签到post
export const getUserSign = data => {
  return reqAxios({
    method: 'post',
    url: `/api/user/userSigin`,
    data,
  });
};

//用户签到查询
export const getUserSigndata = data => {
  return reqAxios.get(`/api/user/userSigninData?signDate=${data}`);
};

//发送验证码 1注册 2登录 3修改手机号 4忘记密码
export const getcode = data => {
  return reqAxios.post(`/api/sms/getVerifyCode`, data);
  // return reqAxios.post(`/sms/getVerifyCode?phonenumber=${data.phonenumber}&type=${data.type}`)
};

//修改密码
export const changePass = data => {
  return reqAxios.post('/api/user/forgetPassword', data);
};

//充值查询
export const getUserchong = () => {
  return reqAxios.get(`/api/ttCoinItem/getCoinItemList`);
};

//充值统计
export const getDataOfRecharge = () => {
  return reqAxios.get('/api/user/dataOfRecharge');
};

export const Recgargezfb = data => {
  return reqAxios({
    method: 'post',
    url: `/api/jiujiaOrder/createOrder`,
    data,
  });
};
export const Recgargewx = data => {
  return reqAxios({
    method: 'post',
    url: `/api/tianxinOrder/createOrder`,
    data,
  });
};

//商城列表
export const shoplist = options => {
  return reqAxios({
    method: 'get',
    url: `/api/shopping/list`,
    params: options,
  });
};
//商城查询条件
export const shopcondition = value => {
  return reqAxios({
    url: `/api/shopping/getShoppingQuery?value=` + value,
    method: 'get',
  });
};

// 商城物品兑换
export const shopconvert = options => {
  return reqAxios({
    method: 'post',
    url: `/api/shopping/exchange`,
    data: options,
  });
};
// 背包
export const getPackSack = data => {
  return reqAxios({
    url: `/api/userPackSack/getPackSack`,
    method: 'post',
    data,
  });
};

// 汰换
export const updateReplacement = data => {
  return reqAxios({
    method: 'post',
    url: `/api/skinsservice/replacementRecord/synthesizeItems`,
    data,
  });
};
// 收支明细
export const getdetail = (pageSize = 15, pageNum = 1) => {
  return reqAxios.get(`/api/user/getBeanChangeRecords?pageSize=${pageSize}&pageNum=${pageNum}`);
};

// 绑定交易链接
export const giveMoneyAPI = options => {
  return reqAxios.post(`/api/user/bindSteamLink?steamLink=${options}`);
};
// 更新用户信息
export const updateUserDetails = data => {
  return reqAxios({
    method: 'post',
    url: '/api/user/updateUserDetails',
    data,
  });
};
// 更新用户头像信息
export const updataavatar = data => {
  return reqAxios({
    method: 'post',
    url: '/api/user/profilePictureUpload',
    data: data,
  });
};
// 绑定推广链接
export const giveUserAPI = options => {
  return reqAxios({
    method: 'post',
    url: `/api/user/bindInvitationCode?invitationCode=${options}`,
  });
};

// 提货账单
export const getExtractBillAPI = data => {
  return reqAxios.get(`/api/user/getDeliveryRecordList?status=${data}&pageSize=1000&pageNum=1`);
};

//文章
export const getArticle = type => {
  return reqAxios.get(`/api/websiteSetup/getContentByType/?alias=${type} `);
};
//查询宝箱分类
export const boxesType = () => {
  return reqAxios.get('/api/bindbox/getBoxList');
};

// /api/battleRoyale/joinRoom
export const getbigkillroom = (rankId, roomid, copies) => {
  return reqAxios.get(`/api/battleRoyale/joinRoom?rankId=${rankId}&roomId=${roomid}&copies=${copies}`);
};
export const getbigkilHistroy = rankId => {
  return reqAxios.get(`/api/battleRoyale/getHistoricalRecord?rankId=${rankId}`);
};

//卡密 /prod-api/api/ttCoinItem/checkKM/{key}
export const getkami = id => {
  return reqAxios.post(`/api/ttCoinItem/checkKM/${id}`);
};

// 查询用户金币 /api/user/getUserRealTimeBean

export const getuserbean = () => {
  return reqAxios.get(`/api/user/getUserRealTimeBean`);
};

// type 1 昨天 2 今天

export const getdiaoluo = () => {
  return reqAxios.get(`/api/user/propRankOfDay/${1}/1`);
};

// /api/user/propRankOfDay

////api/websocket/skins
//协议

export const getagreement = id => {
  return reqAxios.get(`/admin/content/${id}`);
};
//宝箱详情
export const simpleBoxDetail = boxId => {
  // return reqAxios.get(`/api/fight/simpleBoxDetail/boxId=${boxId}`);
  return reqAxios({
    method: 'get',
    url: '/api/fight/simpleBoxDetail',
    params: { boxId },
    // data:{boxId}
  });
};
//极速竞技退出房间/api/fight/fightRoomExit
export const fightRoomExit = data => {
  return reqAxios({
    method: 'post',
    url: '/api/fight/fightRoomExit',
    params: data,
  });
};
//我参与的
export const fightOnMyOwn = data => {
  return reqAxios({
    method: 'post',
    url: '/api/fight/fightOnMyOwn',
    data,
  });
};
//支付接口
export const ApiAddTrans = data => {
  return reqAxios({
    method: 'post',
    url: '/api/zyZFB/ApiAddTrans',
    params: data,
  });
};
//排行榜
export const blendErcashRank = data => {
  return reqAxios({
    method: 'post',
    url: '/api/userAmountRecords/blendErcashRank',
    data,
  });
};
//获取团队信息
export const teamUsers = data => {
  return reqAxios({
    method: 'post',
    url: '/api/userAmountRecords/teamUsers',
    data,
  });
};
export const changePW = data => {
  return reqAxios({
    method: 'post',
    url: '/api/user/changePW',
    data,
  });
};
//获取个人提货记录
export const deliveryRecords = data => {
  return reqAxios({
    method: 'post',
    url: '/api/userAmountRecords/deliveryRecords',
    data,
  });
};
//获取个人充值明细
export const rechargeRecords = data => {
  return reqAxios({
    method: 'get',
    url: '/api/order/list',
    params: data,
  });
};
//历史详情
export const fightDetail = data => {
  return reqAxios({
    method: 'post',
    url: '/api/fight/fightDetail',
    data,
  });
};
//观战获取房间数据
export const audience = id => {
  return reqAxios({
    method: 'get',
    url: `/api/fight/audience?fightId=${id}`,
  });
};
//roll房参与人员
export const getRollPlayers = data => {
  return reqAxios({
    method: 'post',
    url: '/api/roll/getRollPlayers',
    data,
  });
};
//roll房开奖详情
export const getRollOpenPrize = data => {
  return reqAxios({
    method: 'post',
    url: '/api/roll/getRollOpenPrize',
    data,
  });
};
//roll房奖池
export const getRollPrizePool = data => {
  return reqAxios({
    method: 'post',
    url: '/api/roll/getRollPrizePool',
    data,
  });
};
export const earlierHistory = data => {
  return reqAxios({
    method: 'post',
    url: '/api/fight/earlierHistory',
    data,
  });
};
export const endRoll = data => {
  return reqAxios({
    method: 'get',
    url: `/api/roll/endRoll/${data}`,
  });
};
export const packSackGlobalData = () => {
  return reqAxios({
    method: 'get',
    url: `/api/userPackSack/packSackGlobalData`,
  });
};

export const bindBoss = data => {
  return reqAxios({
    method: 'post',
    url: `/api/user/bindBoss`,
    data,
  });
};
export const receiveRedPacket = data => {
  return reqAxios({
    method: 'get',
    url: `/api/bonus/receiveRedPacket/${data}`,
  });
};
export const mayi = data => {
  return reqAxios({
    method: 'post',
    url: `/api/mayi/ApiAddTrans`,
    data,
  });
};
export const getBoxList = data => {
  return reqAxios({
    method: 'post',
    url: `/api/box/getBoxList`,
    data,
  });
};
export const getBoxTypeList = data => {
  return reqAxios({
    method: 'get',
    url: `/api/box/getBoxTypeList/${data.page}/${data.size}`,
  });
};
export const getDetail = boxId => {
  return reqAxios({
    method: 'get',
    url: `/api/box/detail/${boxId}`,
  });
};
export const youbaizhifubao = data => {
  data.payType = 7;
  return reqAxios({
    method: 'post',
    url: `/api/pay/createOrder`,
    data,
  });
};
export const youbaiweixin = data => {
  data.payType = 8;
  return reqAxios({
    method: 'post',
    url: `/api/pay/createOrder`,
    data,
  });
};
export const childDetailByBoss = data => {
  return reqAxios({
    method: 'post',
    url: `/api/blendErcash/childDetailByBoss`,
    data,
  });
};

export const getGradeNorm = () => {
  return reqAxios({
    method: 'get',
    url: '/api/gradeNorm/all',
  });
};

export const ornamentsList = data => {
  return reqAxios({
    method: 'post',
    url: '/api/ornaments/list',
    data,
  });
};

// 创建盲盒
export const fastCreateOwnBox = data => {
  return reqAxios({
    method: 'post',
    url: '/api/box/fastCreateOwnBox',
    data,
  });
};

// 获取头像列表和创建盲盒的底图
export const webResourceList = data => {
  return reqAxios({
    method: 'get',
    url: `/api/webResource/list/${data.type}/${data.page}/${data.size}`,
  });
};

// 获取网站资料
export const webInfo = () => {
  return reqAxios({
    method: 'post',
    url: '/api/webInfo/webInfo',
  });
};

// 创建宝箱计算开启奖励金额
export const computeBoxPrice = data => {
  return reqAxios({
    method: 'post',
    url: '/api/box/computeBoxPrice',
    data,
  });
};

// 宝箱下架
export const boxForbid = id => {
  return reqAxios({
    method: 'get',
    url: `/api/box/boxForbid/${id}`,
  });
};

// 获取任务指标
export const gameConditionById = id => {
  return reqAxios({
    method: 'get',
    url: `/api/gameCondition/byId/${id}`,
  });
};

// 自建盲盒数据统计
export const ownBoxCollect = ({ page, size }) => {
  return reqAxios({
    method: 'get',
    url: `/api/box/ownBoxCollect/${page}/${size}`,
  });
};

// 粉丝充值奖励明细
export const fansRechargePrizeRecords = ({ page, size }) => {
  return reqAxios({
    method: 'get',
    url: `/api/userAmountRecords/fansRechargePrizeRecords/${page}/${size}`,
  });
};
export const getUpgradeComputePrice = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/luckyUpgrade/upgradeComputePrice`,
    data
  });
};
export const getRechargeGreadeNorm = (data) => {
  return reqAxios({
    method: "get",
    url: `/api/rechargeGreadeNorm/list/${data.page}/${data.size}`,
  });
};
export const rechargeGradeBox = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/rechargeGradeBox/openBox`,
    params:data
  });
};
export const rechargeFreeBox = (data) => {
  return reqAxios({
    method: "post",
    url: `/api/rechargeFreeBox/openBox`,
    params:data
  });
};
