import { render, staticRenderFns } from "./lineTitle.vue?vue&type=template&id=958a7708&scoped=true"
import script from "./lineTitle.vue?vue&type=script&lang=js"
export * from "./lineTitle.vue?vue&type=script&lang=js"
import style0 from "./lineTitle.vue?vue&type=style&index=0&id=958a7708&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "958a7708",
  null
  
)

export default component.exports