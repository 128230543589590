import Vue from 'vue';
import Vuex from 'vuex';
import { clearAllInterval } from '@/utils/utils';
import Socket from '@/utils/socket';
import { Message } from 'element-ui';
import router from '../router/index'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mode: '', // pc|mobile
    user_info: {},
    LOGIN_IS_SHOW: false,
    USER_INFO: {},
    wsList: new Set(), // ws连接数组
    voicefile: new Audio(require('../../public/02.mp3')),
    socket:null,
    noReadMsg:false
  },
  mutations: {
    changeMsg(state,flag){
      state.noReadMsg=flag
    },
    //websocket连接
    connectWs(state,userId) {
      // if()
     state.socket = new Socket({
        url: '/ws/game/' +2+'/'+ userId,
        watchLogin: true,
        onmessage: msg => {
          if (!msg.key) return;
          if (msg.key == "TO_BE_DISCONNECTED") {
            router.push('/login');
            state.socket.close();
            state.socket = null;
            state.USER_INFO = {};
            state.user_info = {};
            state.LOGIN_IS_SHOW = true;
            for (const ws of state.wsList) {
              ws.close(); // 关闭每一个 WebSocket 连接
            }
            state.wsList.clear();
            clearAllInterval();
            
            setTimeout(() => {
              Message({ message: '账号在别处登录', type: 'warning' });
              // uni.showToast({
              //   icon: "error",
              //   title: "账号在别处登录",
              // });
            }, 100);
          }
        },
      });
    },
    closeWs(state){
      state.socket.close();
    },
    playAudio(state) {
      if (!state.voicefile.paused) {
        state.voicefile.load();
        return;
      }
      state.voicefile.play();
    },
    USER_INFO(state, message) {
      state.USER_INFO = message;
    },
    LOGIN_IS_SHOW(state, message) {
      state.LOGIN_IS_SHOW = message;
      if (!message) return;
      // 登录失效关闭所有 WebSocket 连接的函数
      if (!state.wsList.size) return;
      for (const ws of state.wsList) {
        ws.close(); // 关闭每一个 WebSocket 连接
      }
      state.USER_INFO = {};
      state.wsList.clear();
      clearAllInterval();
    },
    SET_MODE(state, message) {
      state.mode = message;
    },
    SET_WS_LIST(state, { ws, type }) {
      if (type == 'add') state.wsList.add(ws);
      if (type == 'del' && state.wsList.has(ws)) state.wsList.delete(ws);
    },
  },
  actions: {
    setWsList({ commit }, status) {
      commit('SET_WS_LIST', status);
    },
  },
});
