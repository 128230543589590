<template>
  <div class="card-item">
    <div class="card-item-top">
      <div class="user">
        <template v-for="(item, index) in data.seats">
          <img v-if="item.avatar" :key="index" :src="item.avatar" alt="" />
          <span v-else :key="'s' + index"></span>
        </template>
      </div>
      <div class="inning">
        <i class="el-icon-scissors"></i>
        <span>{{ data.roundNumber }}回合</span>
      </div>
    </div>
    <div class="swiper-container" :id="swiperId">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in data.boxData" :key="index">
          <img :src="item.boxImg01" width="100%" height="100%" />
        </div>
      </div>
      <div class="swiper-button-next">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="swiper-button-prev">
        <i class="el-icon-arrow-left"></i>
      </div>
    </div>
    <div class="card-item-btn">
      <div class="cost">
        <span>总成本：</span>
        <price :price="data.boxPriceTotal" :size="$mode == 'mobile' ? 'small' : 'large'" />
      </div>
      <div class="look_btn" @click="clickBtn" v-if="data.status">{{ data.status == 1 ? '观看战斗' : '查看结果' }}</div>
      <div class="join_btn" @click="clickBtn" v-else>加入战斗</div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

import 'swiper/dist/js/swiper';
import 'swiper/dist/css/swiper.css';

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      swiperId: 'swiper-' + Math.random().toString(36).substring(3),
    };
  },
  mounted() {
    let timer = setTimeout(() => {
      new Swiper('#' + this.swiperId, {
        updateOnWindowResize: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
      clearTimeout(timer);
    }, 200);
  },
  methods: {
    clickBtn() {
      this.$emit('clickBtn', this.data);
    },
    handleGo(id) {
      this.$router.push({ path: '/openbox', query: { id } });
      this.$store.commit('playAudio');
    },
  },
};
</script>

<style lang="less" scoped>
.card-item {
  overflow: hidden;
  background: @bg2;
  border-radius: 12px;
  flex-direction: column;
  border: 1.5px solid @bd2;
  @media @max750 {
    border-radius: 6px;
  }
  .card-item-top {
    display: flex;
    align-items: center;
    padding: 20px 20px 0;
    justify-content: space-between;
    @media @max750 {
      padding: 12px 12px 0;
    }
    .user {
      display: flex;
      img,
      span {
        .wh(50px, 50px);
        display: block;
        border-radius: 50%;
        margin-right: 12px;
        background: @bg2;
        border: 1px solid #f6f6f6;
        @media @max750 {
          .wh(34px, 34px);
          margin-right: 6px;
        }
      }
    }
    .inning {
      border-radius: 20px;
      .sc(16px, #f5f5f5);
      .btn-style(120px, 40px);
      background: @bg3;
      i {
        color: @main;
      }
      span {
        padding-left: 4px;
      }
      @media @max750 {
        .wh(82px, 28px);
        font-size: 12px;
        line-height: 28px;
        border-radius: 14px;
      }
    }
  }
  .card-item-btn {
    height: 64px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    background: @bg3;
    justify-content: space-between;
    .cost {
      display: flex;
      .sc(18px, #ddd);
      align-items: center;
    }
    .join_btn {
      width: 132px;
      height: 48px;
      border: none;
      font-size: 18px;
      color: #ffffff;
      line-height: 48px;
      text-align: center;
      font-weight: bold;
      background: url(../assets/image/loginbtn.png) no-repeat;
      background-size: 100% 100%;
    }
    .look_btn {
      .sc(18px, #888);
      border-radius: 30px;
      .btn-style(132px, 40px);
      background: @bg3;
    }
    @media @max750 {
      height: 40px;
      padding: 0 12px;
      .cost {
        font-size: 14px;
      }
      .look_btn,
      .join_btn {
        .wh(82px, 24px);
        line-height: 24px;
        font-size: 12px;
      }
    }
  }
}
.swiper-container {
  margin: 20px;
  @media @max750 {
    margin: 12px 20px;
  }
  .swiper-slide {
    .flex-center;
    img {
      width: auto;
      height: 108px;
      max-width: 192px;
      @media @max750 {
        height: 72px;
        max-width: 130px;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
    font-size: 34px;
    background: none;
    .flex-center;
    @media @max750 {
      font-size: 24px;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}
</style>
