// 浏览器
export const isBrowser = typeof window !== 'undefined';

// 用户代理信息
export const ua = () => (isBrowser ? window.navigator.userAgent.toLowerCase() : '');

// ios环境
export const isIos = isBrowser && /(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent);

// 安卓环境
export const isAndroid = isBrowser && /(Android)/i.test(window.navigator.userAgent);

// 微信环境
export const isWx = /micromessenger/i.test(ua());

// 企业微信
export const isWxWork = isWx && /wxwork/i.test(ua());

// 浏览器判断
export const browser = () => {
  const u = window.navigator.userAgent;
  return {
    edge: u.indexOf('Edge') > -1, // Edge内核
    trident: u.indexOf('Trident') > -1, // IE内核
    presto: u.indexOf('Presto') > -1, // opera内核
    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, // android终端
    iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, // 是否iPad
    webApp: u.indexOf('Safari') === -1, // 是否web应用程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, // 是否微信 （2015-01-22新增）
    qq: u.match(/\sQQ/i) === 'qq', // 是否QQ
  };
};

// 节流
export const throttle = (func, wait) => {
  let canRun = true;
  return function (...args) {
    if (!canRun) return;
    canRun = false;
    const context = this;
    let timer = setTimeout(() => {
      func.apply(context, args);
      canRun = true;
      clearTimeout(timer);
    }, wait);
  };
};

// 清除所有inter定时器
export const clearAllInterval = () => {
  let end = setInterval(function () {}, 100000);
  for (let i = 1; i <= end; i++) {
    clearInterval(i);
  }
};
