<template>
  <div class="home_banner" v-if="bannerList.length">
    <div class="swiper-container" :id="swiperId">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index" @click="handleC(item)">
          <img :src="item.picture" width="100%" height="100%" />
        </div>
      </div>
      <div v-if="bannerList.length > 1" class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/js/swiper';
import 'swiper/dist/css/swiper.css';

import { getHomeBanner } from '@/api/index';

export default {
  data() {
    return {
      bannerList: [{ picture: require('@/assets/image/index/homebanner.png') }],
      swiperId: 'swiper-' + Math.random().toString(36).substring(3),
    };
  },
  mounted() {
    // this.getbanner();
    let timer = setTimeout(() => {
      new Swiper('#' + this.swiperId, {
        slidesPerView: 1, // 每次只显示一个滑动项
        spaceBetween: 50,
        observer: true,
        observeParents: true,
        loop: this.bannerList.length > 1,
        pagination: {
          // 如果需要分页器
          el: '.swiper-pagination',
          clickable: true, // 点击分页器的指示点控制swiper切换
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
          waitForTransition: true,
        },
      });
      clearTimeout(timer);
    }, 200);
  },
  methods: {
    handleC(item) {
      if (!item.jumpLink) return;
      window.location.href = item.jumpLink;
    },
    getbanner() {
      getHomeBanner().then(res => {
        this.bannerList = res?.data?.data || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home_banner {
  margin: 20px 300px;
  @media @max750 {
    margin: 10px 0;
  }
}
.swiper-container {
  width: 100%;
  height: auto; /* 或者其他你需要的高度 */
  overflow: visible;
  .swiper-slide {
    height: auto;
    width: 100% !important;
  }
}
.swiper-pagination {
  bottom: -14px;
  ::v-deep .swiper-pagination-bullet {
    .wh(12px, 2px);
    border-radius: 0;
    background: #d9d9d9;
  }
  ::v-deep .swiper-pagination-bullet-active {
    .wh(12px, 3px);
    background: @main;
    border-radius: 0;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-carousel {
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  /deep/ .el-carousel__button {
    // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #323436;
  }
  /deep/ .is-active .el-carousel__button {
    // 指示器激活按钮
    background: @main;
  }

  /deep/ .el-carousel__container {
    height: 100%;
  }
}
</style>
