<template>
  <div :class="['goods_item animate__animated animate__bounceIn', { border: isCheck, checked: isCheck && goodsItem?.isSelect }]" @click.stop="handleClick(goodsItem)">
    <div class="goods_odd" v-if="goodsItem.oddsResult&&showOdd">{{ goodsItem.oddsResult }}%</div>
    <div class="goods_img" :style="{ 'background-image': `url(${goodsItem[keys.levelImg]})` }"><img :src="goodsItem[keys.imageUrl]" alt="" /></div>
    <div class="goods_bottom">
      <div class="goods_name textover-f">{{ goodsItem[keys.shortName] || goodsItem[keys.name] }}</div>
      <div class="goods_name textover-f" v-if="goodsItem.exteriorName">{{ goodsItem.exteriorName }}</div>
      <div class="goods_price" v-if="goodsItem.avatar">
        <img v-if="goodsItem.avatar" :src="goodsItem.avatar" alt="" />
        <div class="holderUserNickName">{{ goodsItem[keys.nickName] }}</div>
        <price :price="goodsItem[keys.price]" :size="$store.state.mode == 'mobile' ? 'small' : 'large'" />
      </div>
      <price v-else :price="goodsItem[keys.price]" :size="$store.state.mode == 'mobile' ? 'small' : 'large'" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isCheck: {
      type: Boolean,
      default: false,
    },
    showOdd: {
      type: Boolean,
      default: true,
    },
    goodsItem: {
      type: Object,
      default: () => {},
    },
    keyMap: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    keys() {
      const defaultKey = {
        name: 'name',
        imageUrl: 'imageUrl',
        levelImg: 'levelImg',
        shortName: 'shortName',
        price: 'price',
      };
      return { ...defaultKey, ...this.keyMap };
    },
  },
  methods: {
    handleClick(item) {
      if (!this.isCheck) return;
      this.$store.commit('playAudio');
      this.$emit('handleClick', item);
    },
  },
  mounted(){
  }
};
</script>

<style lang="less" scoped>
.goods_item {
  .flex-column;
  font-size: 14px;
  position: relative;
  border-radius: 10px;
  background: #0d1214;
  box-sizing: border-box;
  transition: all 0.3s ease;
  @media @max750 {
    font-size: 12px;
  }
  .goods_odd {
    top: 4px;
    left: 8px;
    .sc(14px, #ccc);
    position: absolute;
    @media @max750 {
      font-size: 12px;
    }
  }
  .goods_img {
    .flex-center;
    margin: 0 auto;
    .wh(100%, 120px);
    background-size: 100% 100%;
    @media @max750 {
      height: 80px;
    }
    img {
      height: 70%;
    }
  }
  .goods_bottom {
    flex: 1;
    padding: 10px 0;
    background: @bg4;
    .flex-column-center;
    border-radius: 0 0 10px 10px;
    @media @max750 {
      padding: 6px 0;
    }
    .goods_name {
      .sc(16px, #fff);
      padding-bottom: 2px;
      @media @max750 {
        font-size: 14px;
      }
    }
    .goods_price {
      width: 86%;
      .flex-a-center;
      margin: 4px auto 0;
      justify-content: space-between;
      .holderUserNickName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      & > img {
        .wh(30px, 30px);
        border-radius: 50%;
        @media @max750 {
          .wh(24px, 24px);
        }
      }
    }
  }
  ::v-deep .common_price {
    .flex-center;
    @media @max750 {
      img {
        margin: 0;
        width: 12px;
      }
      span {
        font-size: 12px;
        padding-left: 2px;
      }
    }
  }
  &.border {
    border: 1px solid transparent;
  }
  &.checked {
    border-color: @main;
  }
}
</style>
